<template>
  <main class="main programs-open-page">
    <div class="container" v-if="program">
      <BreadcrumbsComponent :title="pageTitle" />
      <section class="prg">
        <div class="prg__inner-fix">
          <div class="prg__left">
            <div class="prg__info">
              <EditorJSComponent :text="JSON.parse(program.text)" />
            </div>
            <div class="prg__tabs" v-if="vehicles.length">
              <h2 class="prg__tabs__title">Программа «{{ program.title }}»</h2>
              <div class="prg__tabs__buttons">
                <template v-for="(vehicle, index) in vehicles">
                  <button
                    class="btn-dark"
                    :class="{ active: activeVehicle === vehicle.id }"
                    :key="index"
                    @click="activeVehicle = vehicle.id"
                    type="button"
                  >
                    {{ vehicle.button_title || vehicle.title }}
                  </button>
                </template>
              </div>
            </div>
          </div>
          <div class="prg__right" v-if="program.slider_img">
            <img
              loading="lazy"
              decoding="async"
              :src="$store.state.api + program.slider_img.img.url"
              :alt="program.slider_img.img.alt"
            />
          </div>
        </div>
        <div class="prg__conditions" v-if="conditions.length">
          <h2 class="prg__conditions__title">Условия</h2>
          <ul class="prg__conditions__list">
            <li v-for="(condition, index) in conditions" :key="index">
              <div class="prg__conditions__content">
                <span class="prg__conditions__date" v-if="condition.from">{{ condition.from }}</span>
                <span class="prg__conditions__number" v-if="condition.value">{{ condition.value }}</span>
                <span class="prg__conditions__modifier" v-if="condition.mod">{{ condition.mod }}</span>
                <h3 class="prg__conditions__name">{{ condition.title }}</h3>
              </div>
              <h3 class="prg__conditions__name">{{ condition.title }}</h3>
            </li>
          </ul>
        </div>
        <div class="prg__inner-options" v-if="options.length">
          <h2 class="prg__tabs__title">Опции</h2>
          <div class="prg__tabs__buttons">
            <template v-for="(option, index) in options">
              <router-link
                :to="{ name: 'option', params: { url: option.link } }"
                :key="index"
                v-if="option.tooltip"
                v-tippy="{ followCursor: false, placement: 'top' }"
                :content="option.tooltip"
                class="btn-dark"
              >
                {{ option.title }}
              </router-link>
              <router-link
                :to="{ name: 'option', params: { url: option.link } }"
                :key="index"
                v-else
                class="btn-dark"
              >
                {{ option.title }}
              </router-link>
            </template>
          </div>
        </div>
      </section>
    </div>
    <CalculatorComponent :vehicles="vehiclesRaw" v-if="$store.state.program_page" />
    <section class="container" v-if="program">
      <div
        class="about"
        v-if="
          program.description &&
          JSON.parse(program.description).blocks &&
          JSON.parse(program.description).blocks.length
        "
      >
        <EditorJSComponent :text="JSON.parse(program.description)" />
      </div>
      <div class="prg__advantages" v-if="advantages.length">
        <h2 class="prg__tabs__title">Преимущества «Лизинг-Трейд»</h2>
        <ul class="prg__advantages-list">
          <li class="prg__advantages-item" v-for="(advantage, index) in advantages" :key="index">
            <i class="icon" v-html="advantage.icon"></i>
            <div class="prg__advantages-content">
              <div class="prg__advantages-title">{{ advantage.title }}</div>
              <div class="prg__advantages-text">
                <EditorJSComponent :text="getAdvantagesDescription(advantage)" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="container proc">
      <h2 class="prg__tabs__title">Процесс оформления лизинга</h2>
      <perfect-scrollbar tag="ul" class="proc__list">
        <li class="proc__item">
          <div class="proc__item__container">
            <img loading="lazy" decoding="async" src="/public/images/select.png" alt="Выбрать имущество" />
            <span class="proc__title">Выбрать имущество</span>
          </div>
          <span class="proc__digit">1</span>
        </li>
        <li class="proc__item">
          <div class="proc__item__container">
            <img loading="lazy" decoding="async" src="/public/images/request.png" alt="Выбрать имущество" />
            <span class="proc__title">Подать заявку на лизинг</span>
          </div>
          <span class="proc__digit">2</span>
        </li>
        <li class="proc__item">
          <div class="proc__item__container">
            <img loading="lazy" decoding="async" src="/public/images/calculate.png" alt="Выбрать имущество" />
            <span class="proc__title">Получить расчет</span>
          </div>
          <span class="proc__digit">3</span>
        </li>
        <li class="proc__item">
          <div class="proc__item__container">
            <img loading="lazy" decoding="async" src="/public/images/deal.png" alt="Выбрать имущество" />
            <span class="proc__title">Оформить договор</span>
          </div>
          <span class="proc__digit">4</span>
        </li>
      </perfect-scrollbar>
    </section>
    <section class="container" v-if="program">
      <div class="inv__tabs">
        <ul class="inv__tabs__list">
          <li :class="{ active: activeTab === 0 }" @click="activeTab = 0" v-if="showDocsTab">
            <FileIcon />
            <span>Документация</span>
          </li>
          <li :class="{ active: activeTab === 1 }" @click="activeTab = 1" v-if="program.conditions">
            <InfoIcon />
            <span>Условия</span>
          </li>
        </ul>
        <div class="inv__tabs__content">
          <ul class="docs" v-show="activeTab === 0" v-if="showDocsTab">
            <template v-for="(type, index) in types">
              <li
                class="docs__item docs__item--full"
                :class="{ active: activeTab === type.id }"
                :key="index"
                v-if="program.documents.filter((d) => d.type && d.type.id === type.id).length"
              >
                <span class="docs__title">{{ type.title }}</span>
                <div class="docs__content">
                  <a href="#" class="docs__subtitle">
                    <span class="docs__decorative">{{ type.subtitle }}</span>
                  </a>
                  <div class="docs__list">
                    <template v-for="(doc, i) in program.documents.filter((d) => d.type.id === type.id)">
                      <a
                        :href="$store.state.api + doc.url"
                        class="docs__list__item"
                        :key="i"
                        v-if="doc.url"
                        :download="doc.title"
                        target="_blank"
                      >
                        <DownloadDocumentIcon v-if="!doc.icon" />
                        <i class="icon" v-html="doc.icon" v-else></i>
                        <div class="docs__list__container">
                          <span class="docs__list__title">{{ doc.title }}</span>
                          <span class="docs__list__subtitle">{{ doc.subtitle }}</span>
                        </div>
                      </a>
                      <div class="docs__list__item docs__list__item--no-hover" v-else :key="i">
                        <DownloadDocumentIcon v-if="!doc.icon" />
                        <i class="icon" v-html="doc.icon" v-else></i>
                        <div class="docs__list__container">
                          <span class="docs__list__title">{{ doc.title }}</span>
                          <span class="docs__list__subtitle">{{ doc.subtitle }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <EditorJSComponent
            :text="JSON.parse(program.conditions)"
            v-if="program.conditions"
            v-show="activeTab === 1"
          />
        </div>
      </div>
    </section>
    <ProgramRequest />
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import FileIcon from "components/svg/File.vue";
import InfoIcon from "components/svg/Info.vue";
import DownloadDocumentIcon from "components/svg/DownloadDocument.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ProgramRequest from "@/views/programs/components/Request.vue";
import CalculatorComponent from "components/Calculator.vue";
import { cityIn } from "lvovich";

export default {
  name: "ProgramsPage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_PROGRAM_PAGE", route.params.url);
  },
  data() {
    return {
      activeTab: 0,
      activeVehicle: null,
    };
  },
  computed: {
    pageTitle() {
      let page = {};
      let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
      city = cityIn(city ? city.title : "Казань");
      if (this.$store.state.program_page && this.$store.state.program_page.program) {
        page = this.$store.state.program_page.program;
      }
      return (page.title || page.meta_title).replace(/{{ city }}/g, `в ${city}`);
    },
    showDocsTab() {
      return (
        this.program &&
        this.types.filter((t) => this.program.documents.filter((d) => d.type && d.type.id === t.id).length)
          .length
      );
    },
    program() {
      if (this.$store.state.program_page && this.$store.state.program_page.program) {
        return this.$store.state.program_page.program;
      } else {
        return undefined;
      }
    },
    advantages() {
      if (this.program && this.program.advantages) {
        return this.program.advantages;
      } else {
        return [];
      }
    },
    types() {
      if (
        this.$store.state.program_page &&
        this.$store.state.program_page.document_types &&
        this.$store.state.program_page.document_types.length
      ) {
        return this.$store.state.program_page.document_types;
      } else {
        return [];
      }
    },
    vehicles() {
      if (this.$store.state.program_page && this.$store.state.program_page.vehicles) {
        return this.$store.state.program_page.vehicles.filter(
          (v) => this.conditionsRaw.filter((c) => c.vehicle_id === v.id).length
        );
      } else {
        return [];
      }
    },
    options() {
      if (this.program && this.program.options && this.program.options.length) {
        return this.program.options;
      } else {
        return [];
      }
    },
    vehiclesRaw() {
      if (this.$store.state.program_page && this.$store.state.program_page.vehicles) {
        return this.$store.state.program_page.vehicles;
      } else {
        return [];
      }
    },
    conditionsRaw() {
      if (this.program && this.program.conditions_array.length) {
        return this.program.conditions_array;
      } else {
        return [];
      }
    },
    conditions() {
      if (this.program && this.program.conditions_array.length) {
        if (this.activeVehicle) {
          return this.program.conditions_array.filter((prg) => prg.vehicle_id === this.activeVehicle);
        } else {
          return this.program.conditions_array;
        }
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (this.showDocsTab) {
      this.activeTab = 0;
    } else {
      this.activeTab = 1;
    }
    if (this.vehicles && this.vehicles.length) {
      this.activeVehicle = this.vehicles[0].id;
    }
  },
  methods: {
    getAdvantagesDescription(adv) {
      if (adv && adv.description) {
        let haveError = false;
        try {
          JSON.parse(adv.description);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(adv.description);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: adv.description },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaFullPath = this.$route.fullPath;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.program_page && this.$store.state.program_page.program) {
      data = this.$store.state.program_page.program;
    }
    const title = (data.meta_title || data.title || "").replace(/{{ city }}/g, `в ${city}`);

    let parentMeta = this.$route.meta.parent.map((item, i) => {
      return {
        "@type": "ListItem",
        position: i + 2,
        name: item.title,
        item:
          api +
          "/" +
          this.$router.resolve({
            name: item.name,
            params: item.params,
          }).href,
      };
    });

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              ...parentMeta,
              {
                "@type": "ListItem",
                position: "3",
                name: data.title,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    CalculatorComponent,
    ProgramRequest,
    EditorJSComponent,
    DownloadDocumentIcon,
    InfoIcon,
    FileIcon,
    BreadcrumbsComponent,
    PerfectScrollbar,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/docs.styl"
@import "~@/styles/parts/inv.styl"
@import "~@/styles/parts/prg.styl"
@import "~@/styles/parts/perfect-scrollbar.styl"
@import "~@/styles/parts/proc.styl"
.programs-open-page {
  .about {
    border: 1px solid main_gray;
    padding 30px
  }
}
</style>
