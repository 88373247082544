<template>
  <i class="file-icon icon">
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.38889 4.25V0H0.791667C0.352951 0 0 0.334375 0 0.75V15.25C0 15.6656 0.352951 16 0.791667 16H11.875C12.3137 16 12.6667 15.6656 12.6667 15.25V5H8.18056C7.74514 5 7.38889 4.6625 7.38889 4.25ZM12.6667 3.80938V4H8.44444V0H8.64566C8.85677 0 9.05799 0.078125 9.20642 0.21875L12.4358 3.28125C12.5842 3.42188 12.6667 3.6125 12.6667 3.80938Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "FileIcon",
};
</script>
